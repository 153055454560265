<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

definePage({
	name: "LoginPage",
	meta: {
		name: "Login",
	},
});

const route = useRoute();
const store = useDefaultStore();

useHead({
	title: $t("app.auth.login") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.auth.login") },
		{
			name: "description",
			content: store.META_DATA.NAME_DESCRIPTION,
		},
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS_NO_INDEX },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.auth.login") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: store.META_DATA.PROPERTY_DESCRIPTION,
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});
</script>

<template>
	<div id="login-page">
		<div class="comp-section">
			<login-comp></login-comp>
		</div>
	</div>
</template>

<style lang="scss">
// temp solution start
.full-button {
	border-radius: 0 !important;
}
.new-login-input {
	border-radius: 0 !important;
}
.auth-views {
	display: block !important;
	padding: 0 !important;
}
.auth {
	display: block !important;
}

#auth {
	height: unset !important;
	.auth-views {
		max-width: 100% !important;
	}
}
.login {
	.modern-form {
		background-color: rgba(255, 255, 255, 0.1) !important;
		border-radius: unset;
	}
	.title {
		span {
			display: block;
			text-align: center !important;
			font-size: 16px !important;
			font-family: Roboto, sans-serif !important;
			font-weight: 700 !important;
			margin: 24px 0;
		}
	}

	@media screen and (width > 768px) {
		.title {
			span {
				font-size: 28px !important;
				line-height: 32px !important;
			}
		}
	}

	@media screen and (width > 1024px) {
		.title {
			span {
				font-size: 34px !important;
				line-height: 40px !important;
			}
		}
	}

	@media screen and (width > 1572px) {
		.title {
			span {
				font-size: 30px !important;
				line-height: 35.16px !important;
			}
		}
	}
}
#login-page {
	height: calc(100vh - $nav-top-bar-sm) !important;
	width: 100%;
	background-image: url("@/assets/image/regularTop/regular-cover.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;

	&::before {
		@include overlay-mixin;

		background: $overlay-hero;
	}

	.image-section,
	.comp-section {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}

	.image-section {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;

		.title {
			font-family: Anton, sans-serif;
			font-size: clamp(1rem, 4vw, 60px);
			font-style: normal;
			font-weight: 400;
		}

		.desc {
			font-family: "Open Sans", sans-serif;
			font-size: clamp(0.8rem, 3.5vw, 24px);
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		img {
			width: 100%;
			max-width: 900px;
		}
	}

	@media screen and (width > 768px) {
		flex-direction: row;

		.image-section {
			flex-basis: 80%;
		}
		.comp-section {
			min-width: 400px;

			> * {
				width: 100%;
			}
		}
	}
}
</style>
