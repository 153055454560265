import { createRouter, createWebHistory } from "vue-router/auto";
import {
	type RouteLocationNormalized,
	type NavigationGuardNext,
} from "vue-router";
import { useSessionStore } from "@/store/session";
import { useDefaultStore } from "@/store";

const router = createRouter({
	history: createWebHistory(),
});

router.beforeEach(
	(
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: NavigationGuardNext,
	) => {
		if (to.name === "CatchAllPage") {
			next("/");
		} else if (to.path.startsWith("/admin")) {
			if (useSessionStore().isLoggedIn) {
				next();
			} else {
				next({ path: "/" });
			}
		} else
			switch (to.path) {
				case "/pravna/blog/Solarna-elektrana-1052%20kWp-Vahali-Brodogradiliste-Sremska%2520Mitrovica": {
					next({
						path: "/pravna/blog/solarna-elektrana-1052-kwp-vahali-brodogradiliste-sremska-mitrovica",
						query: to.query,
					});

					break;
				}
				case "/pravna/blog/Solarna%2520elektrana-400%2520kWp-Moka%2520Cacak%2520D.o.o": {
					next({
						path: "/pravna/blog/solarna-elektrana-400-kwp-moka-cacak-doo",
						query: to.query,
					});

					break;
				}
				case "/pravna/blog/Solarna-elektrana-200%2520kWp-%2520Dn-Company-Vladicin-Han": {
					next({
						path: "/pravna/blog/solarna-elektrana-200-kwp-dn-company-vladicin-han",
						query: to.query,
					});

					break;
				}
				case "/pravna/blog/Solarna-elektrana-160%20kWp-Niz%20Feniks%20D.o.o-Cacak": {
					next({
						path: "/pravna/blog/solarna-elektrana-160-kwp-feniks-cacak",
						query: to.query,
					});

					break;
				}
				case "/blog/Elektrifikacija%25202.0-Kako-izgraditi-solarnu-elektranu-i-postati-kupac-proizvođač-E1": {
					next({
						path: "/blog/elektrifikacija-kako-izgraditi-solarnu-elektranu-i-postati-kupac-proizvodjac-e1",
						query: to.query,
					});

					break;
				}
				case "/pravna/blog/Solarna%2520elektrana%2520snage%2520140%2520kWP": {
					next({
						path: "/pravna/blog/solarna-elektrana-snage-20-kwp",
						query: to.query,
					});

					break;
				}
				default: {
					if (to.path !== "/" && to.path.endsWith("/")) {
						next({ path: to.path.slice(0, -1), query: to.query });
					} else {
						next();
					}
				}
			}
	},
);

router.afterEach(() => {
	setTimeout(() => {
		window.scroll({
			top: 0,
			behavior: "smooth",
		});
	}, 100);
});

export default router;
