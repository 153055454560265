<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const route = useRoute();
const store = useDefaultStore();

definePage({
	name: "PrivacyPolicyPage",
});

useHead({
	title: $t("app.privacyPolicy.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.privacyPolicy.pageTitle") },
		{ name: "description", content: $t("app.privacyPolicy.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.privacyPolicy.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.privacyPolicy.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

onMounted(() => {
	const page_title = $t("app.privacyPolicy.pageTitle") + " | " + store.APP_NAME;
	const page_location = window.location.href;
	store.sendPageview(page_title, page_location);
});
</script>

<template lang="pug">
.terms-of-use-wrapper
	h1.mainTitle {{ $t("app.privacyPolicy.mainTitle") }}
	.title {{ $t("app.privacyPolicy.title1") }}
	.text {{ $t("app.privacyPolicy.text1") }}
	.title {{ $t("app.privacyPolicy.title2") }}
	.text {{ $t("app.privacyPolicy.text2") }}
	.title {{ $t("app.privacyPolicy.title3") }}
	pre.text {{ $t("app.privacyPolicy.text3") }}
	.title {{ $t("app.privacyPolicy.title4") }}
	.text {{ $t("app.privacyPolicy.text4") }}
	.title {{ $t("app.privacyPolicy.title5") }}
	.text {{ $t("app.privacyPolicy.text5") }}
	.title {{ $t("app.privacyPolicy.title6") }}
	.text {{ $t("app.privacyPolicy.text6") }}
	.title {{ $t("app.privacyPolicy.title7") }}
	pre.text {{ $t("app.privacyPolicy.text7") }}
</template>

<style lang="scss" scoped>
.terms-of-use-wrapper {
	padding: 24px;

	.mainTitle {
		font-family: Pelinka, sans-serif;
		font-size: 18px;
		line-height: 24px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 24px;
	}
	.title {
		font-family: Pelinka, sans-serif;
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 24px;
		font-weight: 700;
	}

	.text {
		font-family: "Galano Grotesque Alt", sans-serif;
		font-size: 16px;
		font-weight: 300;
		line-height: 20px;
		display: flex;
		text-align: justify;
		margin-bottom: 48px;
		white-space: pre-wrap;
		word-wrap: break-word;
	}
}

@media screen and (width >= 760px) {
	.terms-of-use-wrapper {
		.mainTitle {
			font-size: 30px;
			line-height: 38px;
		}

		.title,
		.text {
			font-size: 18px;
			line-height: 22px;
		}
	}
}

@media screen and (width >= 1024px) {
	.terms-of-use-wrapper {
		padding: 85px;

		.mainTitle {
			font-size: 40px;
			line-height: 46px;
			margin-bottom: 50px;
		}

		.title {
			font-size: 30px;
			line-height: 40.5px;
			margin-bottom: 40px;
		}

		.paragraph {
			font-size: 30px;
			line-height: 40.5px;
			margin-bottom: 100px;
		}
	}
}
</style>
