<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const route = useRoute();
const store = useDefaultStore();

definePage({
	name: "InstallationProcessPage",
});

useHead({
	title: $t("app.installationProcess.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.installationProcess.pageTitle") },
		{ name: "description", content: $t("app.installationProcess.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.installationProcess.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.installationProcess.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

onMounted(() => {
	const page_title =
		$t("app.installationProcess.pageTitle") + " | " + store.APP_NAME;
	const page_location = window.location.href;
	store.sendPageview(page_title, page_location);
});
</script>

<template lang="pug">
.installation-process-wrapper
	h1.title {{ $t("app.installationProcess.title") }}
	steps-vertical.vertical(:hoverBool="true")
	steps-vertical1024.vertical-1024
</template>

<style lang="scss" scoped>
.installation-process-wrapper {
	padding: 24px 0 85px;
	.horizontal {
		display: none;
	}

	.vertical-1024 {
		display: none;
	}
	.title {
		font-family: Pelinka, sans-serif;
		font-weight: 700;
		font-size: 18px;
		line-height: 24px;
		color: $app-dark-color;
		margin-bottom: 24px;
		text-align: center;
		text-transform: uppercase;
		padding: 0 24px;
	}
	.button-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 100px;
	}
}

@media screen and (width >= 760px) {
	.installation-process-wrapper {
		padding: 24px 0 85px;
		.title {
			font-size: 30px;
			line-height: 38px;
			margin: 26px 0 50px;
		}
	}
}

@media screen and (width >= 1024px) {
	.installation-process-wrapper {
		margin-top: 0;
		padding: 85px 0;
		.title {
			font-size: 40px;
			line-height: 46px;
			margin: 61px 0 85px;
		}
		.button-wrapper {
			margin-top: 185px;
		}
		.vertical {
			display: none;
		}

		.vertical-1024 {
			display: block;
		}
	}
}

@media screen and (width >= 1024px) {
	.installation-process-wrapper {
		.title {
			margin: 0 0 100px;
		}
		.button-wrapper {
			margin-top: 140px;
		}
	}
}

@media screen and (width >= 1572px) {
	.installation-process-wrapper {
		.button-wrapper {
			margin-top: 224px;
		}
	}
}
</style>
