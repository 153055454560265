<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";
import { useReferencesStore } from "@/store/references";
import testimonialsCover from "@/assets/image/testimonials/solarna-farma-vise-solarnih-panela-na-zemlji.png";

const route = useRoute();
const store = useDefaultStore();
const refStore = useReferencesStore();

definePage({
	name: "RegularTestimonials",
});

useHead({
	title: $t("app.regularTestimonials.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.regularTestimonials.pageTitle") },
		{ name: "description", content: $t("app.regularTestimonials.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.regularTestimonials.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.regularTestimonials.metaDesc"),
		},
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

onMounted(() => {
	const page_title =
		$t("app.regularTestimonials.pageTitle") + " | " + store.APP_NAME;
	const page_location = window.location.href;
	store.sendPageview(page_title, page_location);
});
</script>

<template lang="pug">
top-cover(
	:cover="testimonialsCover",
	:title="$t('app.regularTestimonials.title1')"
)
template(
	v-for="(ref, index) in refStore.testimonialsListRegular",
	:key="ref.id"
)
	testimonial-comp(
		:theme="ref.id % 2 === 0 ? 'dark' : 'lighter'",
		:direction="index % 2 === 0 ? 'normal' : 'reverse'",
		:cover="ref.video",
		:content="ref.text"
	)
</template>

<style lang="scss" scoped></style>
