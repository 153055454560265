<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const route = useRoute();
const store = useDefaultStore();
definePage({
	name: "ComplaintsPage",
});

useHead({
	title: $t("app.complaints.title1") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.complaints.title1") },
		{ name: "description", content: $t("app.complaints.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.complaints.title1") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.complaints.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
	link: [
		{
			rel: "canonical",
			href: window.location.origin + route.path,
		},
	],
});

onMounted(() => {
	const page_title = $t("app.complaints.title1") + " | " + store.APP_NAME;
	const page_location = window.location.href;
	store.sendPageview(page_title, page_location);
});
</script>

<template lang="pug">
.terms-of-use-wrapper
	h1.title {{ $t("app.complaints.title1") }}
	.text {{ $t("app.complaints.text1") }}
	.title {{ $t("app.complaints.title2") }}
	//- .text {{ $t("app.complaints.text2") }}
	.text Reklamacija je zakonom regulisani postupak rešavanja prigovora kupca zbog nedostataka na isporučenom proizvodu. Ukoliko primetite da na sistemu koji vam je ugrađen postoji neki proizvodno tehnološki nedostatak ili skrivena mana i kada vam taj nedostatak otežava ili onemogućava upotrebu proizvoda na uobičajeni način, imate pravo da podnesete reklamaciju u cilju otklanjanja ovog nedostatka. Razlog za reklamaciju može biti i nesaobraznost isporučenog i naručenog proizvoda. U ovom slučaju molimo Vas da nas pozovite telefonom na broj 063/267-266 adresu reklamacije@suncica.co.rs i navedete razlog reklamacije. U najkraćem mogućem roku, a najkasnije u roku od 8 dana od dana prijema reklamacije pisanim ili elektronskim putem, odgovorićemo na izjavljenu reklamaciju i obavestićemo Vas o daljem postupanju. Rok za rešavanje reklamacije je 15 dana od trenutka prijave iste.
</template>

<style lang="scss" scoped>
.terms-of-use-wrapper {
	padding: 24px;

	.title {
		font-family: Pelinka, sans-serif;
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 24px;
		font-weight: 700;
	}

	.text {
		font-family: "Galano Grotesque Alt", sans-serif;
		font-size: 16px;
		font-weight: 300;
		line-height: 20px;
		display: flex;
		text-align: justify;
		margin-bottom: 48px;
	}
}

@media screen and (width >= 760px) {
	.terms-of-use-wrapper {
		.title {
			font-size: 18px;
			line-height: 22px;
		}

		.text {
			font-size: 18px;
			line-height: 22px;
		}
	}
}

@media screen and (width >= 1024px) {
	.terms-of-use-wrapper {
		padding: 85px;

		.title {
			font-size: 30px;
			line-height: 40.5px;
			margin-bottom: 40px;
		}

		.text {
			font-size: 20px;
			line-height: 27px;
			margin-bottom: 100px;
		}
	}
}
</style>
