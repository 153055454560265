<script setup lang="ts">
definePage({
	name: "AdminParent",
	redirect: "/admin/novi-post",
});
</script>

<template lang="pug">
header-bar-top
.admin-wrapper
	.admin-sidebar-wrapper
		admin-side-bar
	.admin-router-view-wrapper
		router-view(v-slot="{ Component }")
			component(:is="Component")
</template>
