<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

const route = useRoute();
const store = useDefaultStore();

definePage({
	name: "AboutPage",
});

useHead({
	title: $t("app.about.pageTitle") + " | " + store.APP_NAME,
	meta: [
		{ name: "title", content: $t("app.about.pageTitle") },
		{ name: "description", content: $t("app.about.metaDesc") },
		{ name: "robots", content: store.META_DATA.NAME_ROBOTS },
		{ name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: $t("app.about.pageTitle") + " | " + store.APP_NAME,
		},
		{
			property: "og:description",
			content: $t("app.about.metaDesc"),
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.APP_NAME },
		{ property: "og:type", content: "website" },
	],
});

onMounted(() => {
	const page_title = $t("app.about.pageTitle") + " | " + store.APP_NAME;
	const page_location = window.location.href;
	store.sendPageview(page_title, page_location);
});
</script>

<template lang="pug">
about-section-first
about-section-second
about-section-third
</template>

<style lang="scss" scoped></style>
